import React, { useState } from "react";
import { Card, CardBody, Input, Button, Spinner } from "@nextui-org/react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@nextui-org/react";
import { MdEmail } from "react-icons/md";

const ExtraGuestsModal = ({ guests, isModalOpen, handleFirstNameChange, handleLastNameChange, cancel, addExtraGuests }) => {
    const inputStyle = {
        label: "text-black/50 dark:text-white/90",
        input: [
            "bg-transparent",
            "text-black/90 dark:text-white/90",
            "placeholder:text-default-700/50 dark:placeholder:text-white/60",
        ],
        innerWrapper: "bg-transparent",
        inputWrapper: [
            "shadow-xl",
            "bg-default-200/50",
            "dark:bg-default/60",
            "backdrop-blur-xl",
            "backdrop-saturate-200",
            "hover:bg-default-200/70",
            "dark:hover:bg-default/70",
            "group-data-[focused=true]:bg-default-200/50",
            "dark:group-data-[focused=true]:bg-default/60",
            "!cursor-text",
        ],
    };

    return (
        <Modal
            backdrop="opaque"
            isOpen={isModalOpen}
            onClose={cancel}
            radius="lg"
            classNames={{
                body: "py-6",
                backdrop: "bg-[#4a6741]/20 backdrop-opacity-20",
                base: "border-[#292f46] bg-[#19172c] dark:bg-[#4a6741] text-[#fff]",
                header: "border-b-[1px] border-[#fff]",
                footer: "border-t-[1px] border-[#fff]",
                closeButton: "hover:bg-white/5 active:bg-white/10",
            }}
            placement="center"
        >
            <ModalContent>
                <>
                    <ModalHeader className="flex flex-col gap-1">Extra Guests</ModalHeader>
                    <ModalBody>
                        <p>
                            It looks like you have some extra guests! Please fill out their names below or leave blank if you do not have an extra.
                        </p>
                        {guests.map((guest) => {
                            if (guest.includes("Guest")) {
                                const guestKey = guest;
                                return (
                                    <div className="flex w-full flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
                                        <Input
                                            label={`${guest} First Name`}
                                            key={`${guestKey}_first`}
                                            isClearable
                                            radius="lg"
                                            classNames={inputStyle}
                                            onChange={(e) => { handleFirstNameChange(guestKey, e) }}
                                            isRequired
                                        />
                                        <Input
                                            label={`${guest} Last Name`}
                                            key={`${guestKey}_last`}
                                            isClearable
                                            radius="lg"
                                            classNames={inputStyle}
                                            onChange={(e) => { handleLastNameChange(guestKey, e) }}
                                            isRequired
                                        />
                                    </div>
                                )
                            } else {
                                return <p>{guest}</p>
                            }
                        })}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="foreground" variant="light" onPress={() => { cancel() }}>
                            Close
                        </Button>
                        <Button className="bg-[#89a581] shadow-lg shadow-green-500/20" onPress={() => { addExtraGuests() }}>
                            Submit
                        </Button>
                    </ModalFooter>
                </>
            </ModalContent>
        </Modal >
    )
}

const DuplicateNameModal = ({ first, last, isModalOpen, handleEmailChange, cancel, confirmEmail, error }) => {
    const inputStyle = {
        label: "text-black/50 dark:text-white/90",
        input: [
            "bg-transparent",
            "text-black/90 dark:text-white/90",
            "placeholder:text-default-700/50 dark:placeholder:text-white/60",
        ],
        innerWrapper: "bg-transparent",
        inputWrapper: [
            "shadow-xl",
            "bg-default-200/50",
            "dark:bg-default/60",
            "backdrop-blur-xl",
            "backdrop-saturate-200",
            "hover:bg-default-200/70",
            "dark:hover:bg-default/70",
            "group-data-[focused=true]:bg-default-200/50",
            "dark:group-data-[focused=true]:bg-default/60",
            "!cursor-text",
        ],
    };

    return (
        <Modal
            backdrop="opaque"
            isOpen={isModalOpen}
            onClose={cancel}
            radius="lg"
            classNames={{
                body: "py-6",
                backdrop: "bg-[#4a6741]/20 backdrop-opacity-20",
                base: "border-[#292f46] bg-[#19172c] dark:bg-[#4a6741] text-[#fff]",
                header: "border-b-[1px] border-[#fff]",
                footer: "border-t-[1px] border-[#fff]",
                closeButton: "hover:bg-white/5 active:bg-white/10",
            }}
            placement="center"
        >
            <ModalContent>
                <>
                    <ModalHeader className="flex flex-col gap-1">Confirm Email</ModalHeader>
                    <ModalBody>
                        <p>
                            Looks like there are multiple guests with this name! Please confirm your email to login or login under a different name in the family.
                        </p>
                        <Input
                            label='Email'
                            key={`${first}_${last}`}
                            radius="lg"
                            classNames={inputStyle}
                            onChange={(e) => { handleEmailChange(e.target.value) }}
                            isRequired
                            color={error ? "danger" : "default"}
                            isInvalid={error}
                            startContent={
                                <MdEmail className="text-2xl pointer-events-none flex-shrink-0 mr-2" />
                            }
                        />
                        {error && <div className="text-red-600 font-bold">Looks like this email didn't match! Please try another email, login with a different name, or contact us at rahulravina24@gmail.com</div>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="foreground" variant="light" onPress={() => { cancel() }}>
                            Close
                        </Button>
                        <Button className="bg-[#89a581] shadow-lg shadow-green-500/20" onPress={() => { confirmEmail() }}>
                            Submit
                        </Button>
                    </ModalFooter>
                </>
            </ModalContent>
        </Modal >
    )
}

const RsvpLoginForm = ({ callback, setDefaults, setRsvps }) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [data, setData] = useState([]);
    const [prevRsvp, setPrevRsvp] = useState({});
    const [extraGuests, setExtraGuests] = useState({})
    const [isError, setIsError] = useState(false);
    const [confirmationEmail, setConfirmationEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    async function adminLogin() {
        fetch(`https://api-rahulwedsravina.netlify.app/.netlify/functions/api/getRsvps`, { headers: { "api-key": '41cee51c-32cc-489c-8fa0-100d8971b613' } }).then((resp) => {
            resp.json().then((json) => {
                setRsvps(json);
            })
        })
    }

    async function login() {
        if (firstName.toLowerCase() === 'admin' && lastName === 'rAHULrAVwedding$24') {
            await adminLogin();
            return;
        }
        setIsLoading(true);
        let familyId;
        const defaults = { grahshanti: {}, mehndi: {}, sangeet: {}, wedding: {}, reception: {} };
        let url;
        if (confirmationEmail !== '') {
            url = `https://api-rahulwedsravina.netlify.app/.netlify/functions/api/getFromNameAndEmail/${firstName}/${lastName}/${confirmationEmail}`;
        } else {
            url = `https://api-rahulwedsravina.netlify.app/.netlify/functions/api/getFromName/${firstName}/${lastName}`;
        }
        fetch(url, { headers: { "api-key": '41cee51c-32cc-489c-8fa0-100d8971b613' } }).then((resp) => {
            resp.json().then((json) => {
                if (json.length > 0) {
                    // Only accepts if there is one result. Open dialog for confirmation Email otherwise.
                    if (checkForSameId(json)) {
                        familyId = json[0].id;
                        fetch(`https://api-rahulwedsravina.netlify.app/.netlify/functions/api/getRsvpFromId/${familyId}`, { headers: { "api-key": '41cee51c-32cc-489c-8fa0-100d8971b613' } })
                            .then((resp2) => {
                                resp2.json()
                                    .then((json2) => {
                                        json2.map((guest) => {
                                            Object.keys(defaults).map((eventName) => {
                                                if (guest[eventName] === 1) {
                                                    defaults[eventName] = { ...defaults[eventName], [`${guest.first_name}_${guest.last_name}`]: 1 }
                                                } else {
                                                    defaults[eventName] = { ...defaults[eventName], [`${guest.first_name}_${guest.last_name}`]: 0 }
                                                }
                                            })
                                        })
                                        setPrevRsvp(defaults);
                                    }).then(() => {
                                        setData(json);
                                        const guests = getGuests(json);
                                        if (!guests.includes("Guest1")) {
                                            finalizeLogin(defaults, json);
                                        } else {
                                            const extras = {};
                                            guests.map((guest) => {
                                                if (guest.includes("Guest")) {
                                                    extras[guest] = {};
                                                }
                                            });
                                            setExtraGuests(extras);
                                            setIsModalOpen(true);
                                        }
                                    })
                            })
                    } else {
                        setIsConfirmationModalOpen(true);
                    }
                } else {
                    setIsError(true);
                }
            })
            setIsLoading(false);
        })
    }

    const checkForSameId = (json) => {
        for (let i = 1; i < json.length; i++) {
            if (json[i].id !== json[i - 1].id) {
                return false;
            }
        }
        return true;
    }

    async function addExtraGuests() {
        const reqBody = [];
        const familyId = data[0].id;
        Object.keys(extraGuests).map((extra) => {
            reqBody.push({ ...extraGuests[extra], id: familyId, original_name: extra })
        })
        var myHeaders = new Headers();
        myHeaders.append("api-key", "41cee51c-32cc-489c-8fa0-100d8971b613");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(reqBody);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api-rahulwedsravina.netlify.app/.netlify/functions/api/updateGuest", requestOptions)
            .catch(error => console.log('error', error));

        let dataCopy = data;
        reqBody.map((update) => {
            data.map((guest, index) => {
                if (update.original_name === guest.first_name) {
                    if (update.first_name === undefined) {
                        dataCopy = dataCopy.slice(0, index).concat(dataCopy.slice(index + 1))
                    } else {
                        dataCopy[index].first_name = update.first_name;
                        dataCopy[index].last_name = update.last_name;
                    }
                }
            })
        });
        console.log(dataCopy)
        setData(dataCopy);

        setIsModalOpen(false);
        finalizeLogin(prevRsvp, dataCopy);
    }

    async function confirmEmail() {
        login();
    }

    function getGuests(loginResp) {
        const names = [];
        loginResp.map((guest) => {
            names.push(`${guest.first_name} ${guest.last_name}`.trim())
        })
        return names;
    }

    const finalizeLogin = (defaults, json) => {
        setDefaults(defaults);
        callback(json);
    }

    const cancel = () => {
        setIsModalOpen(false);
        setIsConfirmationModalOpen(false);
        setData([]);
        setPrevRsvp(null);
        setConfirmationEmail('');
    }

    const handleFirstNameChange = (guestKey, e) => {
        setExtraGuests(
            {
                ...extraGuests,
                [guestKey]: { ...extraGuests[guestKey], first_name: e.target.value }
            }
        );
    };
    const handleLastNameChange = (guestKey, e) => {
        setExtraGuests(
            {
                ...extraGuests,
                [guestKey]: { ...extraGuests[guestKey], last_name: e.target.value }
            }
        );
    };

    const handleEnterPress = (e) => {
        if (e.key === 'Enter') {
            login();
        }
    };

    return (
        <>
            <Card
                isBlurred
                className="border-none bg-background/60 dark:bg-default-100/50 max-w-[610px]"
                shadow="sm"
                style={{ margin: "auto" }}
            >
                <CardBody className="shadow-lg">
                    <div className="flex flex-col justify-between">
                        <div className="flex flex-col gap-0">
                            <h3 className="font-semibold text-foreground/90">Login</h3>
                            <div className="w-full flex flex-col gap-4">
                                <Input
                                    size="md"
                                    name="first"
                                    type="name"
                                    placeholder="First Name"
                                    onChange={(event) => setFirstName(event.target.value)}
                                    isRequired
                                    color={isError ? "danger" : "default"}
                                    isInvalid={isError}
                                    onKeyDown={handleEnterPress}
                                />
                                <Input
                                    size="md"
                                    name="last"
                                    type={firstName === 'admin' ? 'password' : 'name'}
                                    placeholder="Last Name"
                                    onChange={(event) => setLastName(event.target.value)}
                                    isRequired
                                    color={isError ? "danger" : "default"}
                                    isInvalid={isError}
                                    onKeyDown={handleEnterPress}
                                />
                                {isError && <div className="text-red-600 font-bold">We couldn't find this first and last name. Please try another name or contact us at rahulravina24@gmail.com</div>}
                                <Button type="submit" radius="full" className="bg-gradient-to-tr from-green-800 to-yellow-300 text-white shadow-lg" onClick={login}>
                                    {isLoading ? <Spinner size="sm" /> : 'Submit'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card >
            <ExtraGuestsModal
                isModalOpen={isModalOpen}
                guests={getGuests(data)}
                handleFirstNameChange={handleFirstNameChange}
                handleLastNameChange={handleLastNameChange}
                cancel={cancel}
                addExtraGuests={addExtraGuests}
            />
            <DuplicateNameModal
                isModalOpen={isConfirmationModalOpen}
                first={firstName}
                last={lastName}
                handleEmailChange={setConfirmationEmail}
                cancel={cancel}
                confirmEmail={confirmEmail}
                error={isError}
            />
        </>
    );
}

export default RsvpLoginForm;