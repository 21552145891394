import React from "react";
import { Card, CardBody, CardHeader, Image, Link } from "@nextui-org/react";
import { IoLocationSharp } from "react-icons/io5";
import "./HotelBlock.css"

const hotelList = [
    {
        title: "Westin Mt. Laurel",
        img: "https://media-cdn.tripadvisor.com/media/photo-m/1280/29/4b/01/fc/exterior.jpg",
        map: "https://www.google.com/maps/place/The+Westin+Mount+Laurel/@39.9327577,-74.9628441,17z/data=!3m1!4b1!4m9!3m8!1s0x89c13529d7807373:0x1c21fffb7f157f3f!5m2!4m1!1i2!8m2!3d39.9327536!4d-74.9602692!16s%2Fg%2F1tfpqlkr?hl=en&entry=ttu",
        address: "555 Fellowship Rd, Mt Laurel Township, NJ 08054",
        roomBlock: "https://www.marriott.com/event-reservations/reservation-link.mi?id=1697142280513&key=GRP&app=resvlink",
    },
    {
        title: "Aloft Mt. Laurel",
        img: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/29/6a/2b/05/exterior-light.jpg?w=700&h=-1&s=1",
        map: "https://www.google.com/maps/place/Aloft+Mount+Laurel/@39.931203,-74.963641,17z/data=!3m1!4b1!4m9!3m8!1s0x89c134d622e14345:0xd3c84cc0806e6e0d!5m2!4m1!1i2!8m2!3d39.931199!4d-74.9587701!16s%2Fg%2F1tfdzrqy?hl=en&entry=ttu",
        address: "558 Fellowship Rd, Mt Laurel Township, NJ 08054",
        roomBlock: "https://www.marriott.com/event-reservations/reservation-link.mi?id=1697397407201&key=GRP&app=resvlink",
    },
]

const HotelBlock = () => {
    return (
        <div className="hotels">
            <p>There are two room blocks, a minute away from each other, available for the wedding and reception. Please feel free to book at either location.</p>
            <br />
            <div className="gap-2 grid grid-cols-1 sm:grid-cols-2">
                {hotelList.map((item, index) => (
                    <Card
                        className="py-4"
                        isPressable
                        onPress={() => window.open(item.roomBlock)}>
                        <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                            <p className="uppercase font-bold">{item.title}</p>
                            <Link href={item.map} target="_blank"><IoLocationSharp /> {item.address}</Link>
                        </CardHeader>
                        <CardBody className="overflow-visible py-2">
                            <Image
                                alt="Card background"
                                className="object-cover rounded-xl"
                                src={item.img}
                            />
                        </CardBody>
                    </Card>
                ))}
            </div>
        </div>
    )
}

export default HotelBlock;