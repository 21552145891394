import React from "react";
import { Card, CardBody, CardHeader } from "@nextui-org/react";

const RsvpClosed = () => {
    return (
        <div className="grid place-items-center ">
            <Card
                className="max-w-fit">
                <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                    <h3 className="uppercase font-bold">RSVP Closed</h3>
                </CardHeader>
                <CardBody className="overflow-visible py-2">
                    RSVPs are closed at this time! If you need to make any urgent changes please contact us at rahulravina24@gmail.com
                </CardBody>
            </Card>
        </div>
    )
}

export default RsvpClosed;