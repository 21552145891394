import { useState, useEffect } from "react";
import "./Carousel.css"

const Carousel = ({ imgArr, duration = 4000, children }) => {

    const getKeyframes = () => {
        let str = "";
        let interval = 100 / (imgArr.length)
        for (let i = 0; i < imgArr.length; i++) {
            str += `${i * interval}% {background-image: url(${imgArr[i]});}\n`
        }
        str += `100% {background-image: url(${imgArr[0]});}\n`
        return str;
    }

    const inEffect = `
            @keyframes react-fade-in {
                ${getKeyframes()}
            }
        `;

    const preloadImages = () => {
        let imgs = [];
        for (let i = 0; i < imgArr.length; i++) {
            imgs.push(`url(${imgArr[i]})`)
        }
        return imgs.join();
    }

    return (
        <>
            {children}
            <div style={{ background: 'linear-gradient(rgb(0 0 0 / 60%), transparent 50%)' }} className='carousel-overlay fixed w-full h-full'></div>
            <div className='carousel-item h-full w-full m-auto'>
                <style children={inEffect} />
                <div
                    style={{ backgroundImage: `${preloadImages()}`, animationDuration: `${duration * imgArr.length}ms`, animationName: 'react-fade-in', animationIterationCount: 'infinite', animationDelay: `${duration}ms` }}
                    className='w-full h-full bg-center bg-cover'
                >
                </div>
            </div>
        </>
    );
}

export default Carousel;