import React, { useEffect } from "react";
import { Card, CardHeader, CardBody, Image } from "@nextui-org/react";
import SangeetDance from "../../Images/SangeetDance.png";
import WeddingHands from "../../Images/WeddingHands.png";
import Champagne from "../../Images/Champagne.png";

import "./MyEvents.css"

const MyEvents = ({ data, isAdmin }) => {
    const getSangeet = () => {
        const item = data[0];
        if (isAdmin || item.sangeet > 0) {
            return (
                <Card>
                    <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                        <h3>Sangeet</h3>
                        <h4>Friday, May 17, 2024 at 6:00PM</h4>
                        <h4>Kogok Hall, Potomac, MD 10620</h4>
                    </CardHeader>
                    <CardBody>
                        <div className="grid grid-cols-6 md:grid-cols-12 gap-6 md:gap-4 items-center justify-center">
                            <div className="relative col-span-6 md:col-span-4">
                                <Image
                                    alt="Sangeet Cover"
                                    className="object-cover"
                                    src={SangeetDance}
                                    width="100%"
                                />
                            </div>
                            <div className="flex flex-col col-span-6 md:col-span-8">
                                <p>A sangeet is a traditional Punjabi and North Indian pre-wedding ceremony that involves dancing, singing, and celebrating. The word "sangeet" is Sanskrit for "sung together". The sangeet is a celebration of the union of the couple and the bonding of both families.</p>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            )
        }
    }

    const getWedding = () => {
        const item = data[0];
        if (isAdmin || item.wedding > 0) {
            return (
                <Card>
                    <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                        <h3>Wedding</h3>
                        <h4>Sunday, May 19, 2024 at 9:00AM</h4>
                        <h4>Lucien's Manor, Berlin, NJ 08009</h4>
                    </CardHeader>
                    <CardBody>
                        <div className="grid grid-cols-6 md:grid-cols-12 gap-6 md:gap-4 items-center justify-center">
                            <div className="relative col-span-6 md:col-span-4">
                                <Image
                                    alt="Wedding Cover"
                                    className="object-cover"
                                    src={WeddingHands}
                                    width="100%"
                                />
                            </div>
                            <div className="flex flex-col col-span-6 md:col-span-8">
                                <p>A Hindu wedding is a sacred union involving rituals like garland exchange, lighting a sacred fire, vows, sindoor application, and mangalsutra tying. It symbolizes the couple's commitment and is followed by blessings and a joyous reception.</p>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            )
        }
    }

    const getReception = () => {
        const item = data[0];
        if (isAdmin || item.reception > 0) {
            return (
                <Card>
                    <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                        <h3>Reception</h3>
                        <h4>Sunday, May 19, 2024 at 6:00PM</h4>
                        <h4>Lucien's Manor, Berlin, NJ 08009</h4>
                    </CardHeader>
                    <CardBody>
                        <div className="grid grid-cols-6 md:grid-cols-12 gap-6 md:gap-4 items-center justify-center">
                            <div className="relative col-span-6 md:col-span-4">
                                <Image
                                    alt="Reception Cover"
                                    className="object-cover"
                                    src={Champagne}
                                    width="100%"
                                />
                            </div>
                            <div className="flex flex-col col-span-6 md:col-span-8">
                                <p>The reception is a joyful celebration held after the Hindu wedding ceremony, where family and friends come together to honor and congratulate the newlyweds. It typically involves music, dancing, and feasting, creating a festive atmosphere for all attendees.</p>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            )
        }
    }

    return (
        <div className="events gap-2 grid grid-cols-1">
            {getSangeet()}
            {getWedding()}
            {getReception()}
        </div >
    )
}

export default MyEvents;