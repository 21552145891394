import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChildren } from '@fortawesome/free-solid-svg-icons'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { faSuitcaseRolling } from '@fortawesome/free-solid-svg-icons'
import { faCakeCandles } from '@fortawesome/free-solid-svg-icons'
import { faVirusCovid } from '@fortawesome/free-solid-svg-icons'
import { faCarSide } from '@fortawesome/free-solid-svg-icons'
import { faUmbrellaBeach } from '@fortawesome/free-solid-svg-icons'
import { faGem } from '@fortawesome/free-regular-svg-icons'

import "./CustomVerticalTimeline.css";

const iconStyle = (mobileView) => {
    if (mobileView === true) {
        return {
            background: "rgb(61, 103, 68)",
            color: "#fff",
            width: "50px",
            height: "50px",
            marginLeft: "-5px",
        }
    } else {
        return {
            background: "rgb(61, 103, 68)",
            color: "#fff",
            width: "50px",
            height: "50px",
            marginLeft: "-25px",
        }
    }
}

const CustomVerticalTimeline = ({ mobileView }) => {
    return (
        <div>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--wedding"
                    date="April 2018"
                    iconClassName="timeline-hover"
                    iconStyle={iconStyle(mobileView)}
                    icon={<FontAwesomeIcon icon={faChildren} style={{ scale: "90%" }} />}
                >
                    <h3 className="vertical-timeline-element-title">First Meeting</h3>
                    <p>Rahul and Ravina met at UMD just before Rahul was going to graduate. Sparks were flying and they continued to talk.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--wedding"
                    date="April 2018"
                    iconClassName="timeline-hover"
                    iconStyle={iconStyle(mobileView)}
                    icon={<FontAwesomeIcon icon={faHeart} style={{ scale: "90%" }} />}
                >
                    <h3 className="vertical-timeline-element-title">First Date</h3>
                    <p>Rahul and Ravina went on their first date to Busboy and Poets, followed by bowling. Ravina won bowling, but Rahul likes to say he let her win.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--wedding"
                    date="May 2018"
                    iconClassName="timeline-hover"
                    iconStyle={iconStyle(mobileView)}
                    icon={<FontAwesomeIcon icon={faSuitcaseRolling} style={{ scale: "90%" }} />}
                >
                    <h3 className="vertical-timeline-element-title">Rahul Moves Away</h3>
                    <p>After graduating, Rahul moved away to New Jersey and they found themselves in an uncertain situation. But they were both determined to make their relationship work.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--wedding"
                    date="September 2018"
                    iconClassName="timeline-hover"
                    iconStyle={iconStyle(mobileView)}
                    icon={<FontAwesomeIcon icon={faCakeCandles} style={{ scale: "90%" }} />}
                >
                    <h3 className="vertical-timeline-element-title">First Birthday Celebration</h3>
                    <p>Rahul's and Ravina's birthdays are 3 days apart. They celebrated for the first time together at Stone Tower Winery.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--wedding"
                    date="March 2020"
                    iconClassName="timeline-hover"
                    iconStyle={iconStyle(mobileView)}
                    icon={<FontAwesomeIcon icon={faVirusCovid} style={{ scale: "90%" }} />}
                >
                    <h3 className="vertical-timeline-element-title">Covid Hits</h3>
                    <p>Covid hits and the US goes into lockdown. Rahul and Ravina were not able to see each other for 4 months.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--wedding"
                    date="August 2021"
                    iconClassName="timeline-hover"
                    iconStyle={iconStyle(mobileView)}
                    icon={<FontAwesomeIcon icon={faCarSide} style={{ scale: "90%" }} />}
                >
                    <h3 className="vertical-timeline-element-title">First Family Trip</h3>
                    <p>Rahul and Ravina go on their first family trip with their parents to Philly.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--wedding"
                    date="October 2021"
                    iconClassName="timeline-hover"
                    iconStyle={iconStyle(mobileView)}
                    icon={<FontAwesomeIcon icon={faUmbrellaBeach} style={{ scale: "90%" }} />}
                >
                    <h3 className="vertical-timeline-element-title">Rahul and Ravina's First Trip</h3>
                    <p>Rahul and Ravina go on their first trip together to Miami to enjoy the sun and beach.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--wedding"
                    date="January 3, 2023"
                    iconClassName="timeline-hover"
                    iconStyle={iconStyle(mobileView)}
                    icon={<FontAwesomeIcon icon={faGem} style={{ scale: "90%" }} />}
                >
                    <h3 className="vertical-timeline-element-title">Rahul Proposes</h3>
                    <p>Rahul and Ravina went on a trip to California together. During their stay at Joshua Tree, Rahul took Ravina to the national park for the day. While they were gone Rahul had put together a romantic starlight picnic for Ravina to come back to. They enjoyed some champagne together and Rahul popped the question.</p>
                </VerticalTimelineElement>
            </VerticalTimeline>
        </div>
    )
}

export default CustomVerticalTimeline;