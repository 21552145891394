import React from "react";
import Carousel from "../Carousel/Carousel";
import Countdown from "../Countdown/Countdown";
import lookback from '../../Images/lookback.jpg'
import bench from '../../Images/RosesBench.jpg'
import spin from '../../Images/Spin.jpg'
import hug from '../../Images/hug.jpg'
import picnic from '../../Images/picnic.jpg'
import hugMobile from '../../Images/hug-mobile.jpg'
import spinMobile from '../../Images/spin-mobile.jpg'
import leanMobile from '../../Images/lean-mobile.jpg'
import picnicMobile from '../../Images/picnic-mobile.jpg'

const getImages = (mobileView) => {
    if (mobileView) {
        return [leanMobile, hugMobile, spinMobile, picnicMobile]
    } else {
        return [lookback, bench, hug, spin, picnic]
    }
}

const Home = ({ mobileView }) => {
    return (<Carousel imgArr={getImages(mobileView)}>
        <div className='absolute w-[100%] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white z-10'>
            <p className="text-center font-['Petit_Formal_Script'] text-[6vw] md:text-[3vw] fade-list-item">The Wedding Of</p>
            <p style={{ animationDelay: '500ms' }} className="text-center font-['Alex_Brush'] text-[15vw] md:text-[10vw] fade-list-item">Rahul & Ravina</p>
            <Countdown />
        </div>
    </Carousel>)
}

export default Home;