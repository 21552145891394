import './App.css';
import React from 'react';
import Navbar from './components/Navbar/Navbar';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <React.Fragment>
        <Navbar />
      </React.Fragment>
    </BrowserRouter>
  );
}

export default App;
