import { useRef, useState, useEffect } from "react";
import { Routes, Route, NavLink, useLocation } from "react-router-dom";
import { Button } from "@nextui-org/react";
import { FaBars, FaTimes } from "react-icons/fa";
import { MdCircle } from 'react-icons/md'
import logo from '../../Images/RRLogo.png'
import Home from "../Pages/Home";
import About from "../Pages/About";
import Events from "../Pages/Events";
import Accommodations from "../Pages/Accomodations";
import Rsvp from "../Pages/Rsvp";
import "../../Styles/main.css";
import "./Navbar.css";

const Navbar = () => {
    const navRef = useRef();
    const [mobileView, setMobileView] = useState(window.innerWidth <= 1024);
    const [data, setData] = useState([]);
    const [defaults, setDefaults] = useState({});
    const [rsvps, setRsvps] = useState(null);
    const path = useLocation().pathname;


    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
    };

    const handleLogin = (loginResp) => {
        setData(loginResp);
    }

    useEffect(() => {
        function checkSize() {
            setMobileView(window.innerWidth <= 1024)
        }
        window.addEventListener('resize', checkSize);
        checkSize();
        return () => window.removeEventListener('resize', checkSize);
    }, []);

    const routes = <>
        <Routes>
            <Route exact path="/" element={<Home mobileView={mobileView} />} />
            <Route exact path="/about" element={<About mobileView={mobileView} />} />
            <Route exact path="/accommodations" element={<Accommodations />} />
            <Route exact path="/events" element={<Events data={data} isAdmin={rsvps !== null} />} />
            <Route exact path="/rsvp" element={<Rsvp mobileView={mobileView} data={data} handleLogin={handleLogin} defaults={defaults} setDefaults={setDefaults} rsvps={rsvps} setRsvps={setRsvps} />} />
        </Routes>
    </>

    const loggedOutRoutes = <>
        <Routes>
            <Route path="/" element={<Rsvp mobileView={mobileView} data={data} handleLogin={handleLogin} defaults={defaults} setDefaults={setDefaults} rsvps={rsvps} setRsvps={setRsvps} />} />
            <Route exact path="/about" element={<Rsvp mobileView={mobileView} data={data} handleLogin={handleLogin} defaults={defaults} setDefaults={setDefaults} rsvps={rsvps} setRsvps={setRsvps} />} />
            <Route exact path="/accommodations" element={<Rsvp mobileView={mobileView} data={data} handleLogin={handleLogin} defaults={defaults} setDefaults={setDefaults} rsvps={rsvps} setRsvps={setRsvps} />} />
            <Route exact path="/events" element={<Rsvp mobileView={mobileView} data={data} handleLogin={handleLogin} defaults={defaults} setDefaults={setDefaults} rsvps={rsvps} setRsvps={setRsvps} />} />
            <Route exact path="/rsvp" element={<Rsvp mobileView={mobileView} data={data} handleLogin={handleLogin} defaults={defaults} setDefaults={setDefaults} rsvps={rsvps} setRsvps={setRsvps} />} />
        </Routes>
    </>

    if (data.length > 0 || rsvps !== null) {
        if (mobileView) {
            return (
                <>
                    <header className='z-20'>
                        <nav ref={navRef}>
                            <NavLink to="/" className='w-[100%] h-[20%] border-b-[1px] border-opacity-10 border-slate-50' onClick={showNavbar}>
                                <div className='w-[100%] h-[100%] ml-5'>Home</div>
                            </NavLink>
                            <NavLink to="about" className='w-[100%] h-[20%] border-b-[1px] border-opacity-10 border-slate-50' onClick={showNavbar}>
                                <div className='w-[100%] h-[100%] ml-5'>Our Story</div>
                            </NavLink>
                            <NavLink to="accommodations" className='w-[100%] h-[20%] border-b-[1px] border-opacity-10 border-slate-50' onClick={showNavbar}>
                                <div className='w-[100%] h-[100%] ml-5'>Accommodations</div>
                            </NavLink>
                            <NavLink to="events" className='w-[100%] h-[20%] border-b-[1px] border-opacity-10 border-slate-50' onClick={showNavbar}>
                                <div className='w-[100%] h-[100%] ml-5'>Events</div>
                            </NavLink>
                            <NavLink to="rsvp" className='w-[100%] h-[20%] border-b-[1px] border-opacity-10 border-slate-50' onClick={showNavbar}>
                                <div className='w-[100%] h-[100%] ml-5'>RSVP</div>
                            </NavLink>
                        </nav>
                        <div className="flex gap-4 items-center">
                            <Button isIconOnly color="default" aria-label="Like" onClick={showNavbar}>
                                <FaBars />
                            </Button>
                        </div>
                    </header>
                    {routes}
                </>
            )
        } else {
            return (
                <>
                    <header className='z-20 border-b-[1px] border-opacity-10 border-slate-50' style={{ backgroundColor: path === "/" || path === "/rsvp" ? "transparent" : "rgb(61, 103, 68)" }}>
                        <nav ref={navRef} className='w-10/12'>
                            <div className='w-1/5'>
                                <NavLink to="/about">
                                    Our Story
                                </NavLink>
                            </div>
                            <MdCircle size={5} className="m-10" />
                            <div className='w-1/5'>
                                <NavLink to="/accommodations">
                                    Accommodations
                                </NavLink>
                            </div>
                            <MdCircle size={5} className="m-10" />
                            <div className='w-1/5'>
                                <NavLink to="/">
                                    <img src={logo} className='w-[50px]' />
                                </NavLink>
                            </div>
                            <MdCircle size={5} className="m-10" />
                            <div className='w-1/5'>
                                <NavLink to="/events">
                                    Events
                                </NavLink>
                            </div>
                            <MdCircle size={5} className="m-10" />
                            <div className='w-1/5'>
                                <NavLink to="/rsvp">
                                    RSVP
                                </NavLink>
                            </div>
                            <button
                                className="nav-btn nav-close-btn"
                                onClick={showNavbar}>
                                <FaTimes />
                            </button>
                        </nav>
                        <button className="nav-btn" onClick={showNavbar}>
                            <FaBars />
                        </button>
                    </header>
                    {routes}
                </>
            );
        }
    } else {
        return (
            <>
                {loggedOutRoutes}
            </>
        )
    }
}

export default Navbar;