import React, { useState, useMemo, useCallback } from "react";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Input, Button, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react";
import { MdOutlineSearch } from "react-icons/md";
import { HiChevronDown } from "react-icons/hi2";

const RsvpAdminPage = ({ rsvps }) => {
    const [filterValue, setFilterValue] = useState("");
    const [sortDescriptor, setSortDescriptor] = useState({
        column: "side",
        direction: "descending",
    });
    const [sideFilter, setSideFilter] = useState("all");

    const getTotalsBySide = () => {
        var grahTotalGroom = 0;
        var grahTotalBride = 0;
        var mehndiTotalGroom = 0;
        var mehndiTotalBride = 0;
        var sangeetTotalGroom = 3;
        var sangeetTotalBride = 4;
        var weddingTotalGroom = 3;
        var weddingTotalBride = 4;
        var recepTotalGroom = 3;
        var recepTotalBride = 4;
        rsvps.forEach((resp) => {
            if (resp.side === 'groom') {
                grahTotalGroom += resp.grahshanti
                mehndiTotalGroom += resp.mehndi
                sangeetTotalGroom += resp.sangeet
                weddingTotalGroom += resp.wedding
                recepTotalGroom += resp.reception
            } else {
                grahTotalBride += resp.grahshanti
                mehndiTotalBride += resp.mehndi
                sangeetTotalBride += resp.sangeet
                weddingTotalBride += resp.wedding
                recepTotalBride += resp.reception
            }
        })
        return { bride: [grahTotalBride, mehndiTotalBride, sangeetTotalBride, weddingTotalBride, recepTotalBride], groom: [grahTotalGroom, mehndiTotalGroom, sangeetTotalGroom, weddingTotalGroom, recepTotalGroom] }
    }

    const totalsBySide = getTotalsBySide();
    const hasSearchFilter = Boolean(filterValue);

    const filteredItems = React.useMemo(() => {
        let filteredGuests = [...rsvps];

        if (hasSearchFilter) {
            filteredGuests = filteredGuests.filter((guest) =>
                guest.first_name.toLowerCase().includes(filterValue.toLowerCase()) || guest.last_name.toLowerCase().includes(filterValue.toLowerCase())
            );
        }

        if (sideFilter !== "all" && Array.from(sideFilter).length !== 2) {
            filteredGuests = filteredGuests.filter((guest) =>
                Array.from(sideFilter).includes(guest.side),
            );
        }

        return filteredGuests;
    }, [rsvps, filterValue, sideFilter]);

    const sortedItems = useMemo(() => {
        return [...filteredItems].sort((a, b) => {
            const first = a[sortDescriptor.column];
            const second = b[sortDescriptor.column];
            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, filteredItems]);

    const onSearchChange = useCallback((value) => {
        if (value) {
            setFilterValue(value);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = useCallback(() => {
        setFilterValue("")
    }, [])

    const topContent = React.useMemo(() => {
        return (
            <div className="gap-5 grid grid-cols-5">
                <Input
                    isClearable
                    className="w-full col-span-2"
                    placeholder="Search by name..."
                    startContent={<MdOutlineSearch />}
                    value={filterValue}
                    onClear={() => onClear()}
                    onValueChange={onSearchChange}
                />
                <div className="flex gap-3">
                    <Dropdown aria-label="side-dropdown">
                        <DropdownTrigger>
                            <Button endContent={<HiChevronDown className="text-small" />} variant="solid">
                                Side
                            </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                            disallowEmptySelection
                            aria-label="Table Columns"
                            closeOnSelect={false}
                            selectedKeys={sideFilter}
                            selectionMode="multiple"
                            onSelectionChange={setSideFilter}
                        >
                            <DropdownItem key="groom" className="capitalize">
                                Groom
                            </DropdownItem>

                            <DropdownItem key="bride" className="capitalize">
                                Bride
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>
        )
    })

    return (
        <div>
            <Table
                isHeaderSticky
                isStriped
                sortDescriptor={sortDescriptor}
                onSortChange={setSortDescriptor}
                aria-label="Rsvp Table"
                classNames={{
                    wrapper: "max-h-[500px]",
                }}
                topContent={topContent}
                topContentPlacement="outside"
                bottomContent={
                    <Table
                        isStriped
                        classNames={{
                            wrapper: "max-h-[500px]",
                        }}>
                        <TableHeader>
                            <TableColumn key="first">Title</TableColumn>
                            <TableColumn key="grahshanti" allowsSorting>Grahshanti</TableColumn>
                            <TableColumn key="mehndi" allowsSorting>Mehndi</TableColumn>
                            <TableColumn key="sangeet" allowsSorting>Sangeet</TableColumn>
                            <TableColumn key="wedding" allowsSorting>Wedding</TableColumn>
                            <TableColumn key="reception" allowsSorting>Reception</TableColumn>
                        </TableHeader>
                        <TableBody>
                            <TableRow key={`brideTotals`}>
                                <TableCell><p className="font-bold text-lg">Bride Totals</p></TableCell>
                                <TableCell><p className="font-bold text-lg">{totalsBySide.bride[0]}</p></TableCell>
                                <TableCell><p className="font-bold text-lg">{totalsBySide.bride[1]}</p></TableCell>
                                <TableCell><p className="font-bold text-lg">{totalsBySide.bride[2]}</p></TableCell>
                                <TableCell><p className="font-bold text-lg">{totalsBySide.bride[3]}</p></TableCell>
                                <TableCell><p className="font-bold text-lg">{totalsBySide.bride[4]}</p></TableCell>
                            </TableRow>
                            <TableRow key={`groomTotals`}>
                                <TableCell><p className="font-bold text-lg">Groom Totals</p></TableCell>
                                <TableCell><p className="font-bold text-lg">{totalsBySide.groom[0]}</p></TableCell>
                                <TableCell><p className="font-bold text-lg">{totalsBySide.groom[1]}</p></TableCell>
                                <TableCell><p className="font-bold text-lg">{totalsBySide.groom[2]}</p></TableCell>
                                <TableCell><p className="font-bold text-lg">{totalsBySide.groom[3]}</p></TableCell>
                                <TableCell><p className="font-bold text-lg">{totalsBySide.groom[4]}</p></TableCell>
                            </TableRow>
                            <TableRow key={`totals`}>
                                <TableCell><p className="font-bold text-lg">Totals</p></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell><p className="font-bold text-lg">{totalsBySide.groom[2] + totalsBySide.bride[2]}</p></TableCell>
                                <TableCell><p className="font-bold text-lg">{totalsBySide.groom[3] + totalsBySide.bride[3]}</p></TableCell>
                                <TableCell><p className="font-bold text-lg">{totalsBySide.groom[4] + totalsBySide.bride[4]}</p></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                }
                bottomContentPlacement="outside"
            >
                <TableHeader>
                    <TableColumn key="first">First</TableColumn>
                    <TableColumn key="last">Last</TableColumn>
                    <TableColumn key="grahshanti" allowsSorting>Grahshanti</TableColumn>
                    <TableColumn key="mehndi" allowsSorting>Mehndi</TableColumn>
                    <TableColumn key="sangeet" allowsSorting>Sangeet</TableColumn>
                    <TableColumn key="wedding" allowsSorting>Wedding</TableColumn>
                    <TableColumn key="reception" allowsSorting>Reception</TableColumn>
                    <TableColumn key="side" allowsSorting>Side</TableColumn>
                </TableHeader>
                <TableBody items={sortedItems}>
                    {sortedItems.map((item) => {
                        return <TableRow key={`${item.id}_${item.first_name}`}>
                            <TableCell>{item.first_name}</TableCell>
                            <TableCell>{item.last_name}</TableCell>
                            <TableCell>{item.grahshanti}</TableCell>
                            <TableCell>{item.mehndi}</TableCell>
                            <TableCell>{item.sangeet}</TableCell>
                            <TableCell>{item.wedding}</TableCell>
                            <TableCell>{item.reception}</TableCell>
                            <TableCell>{item.side}</TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </div>
    )
}

export default RsvpAdminPage;