import React, { useState } from "react";
import RsvpLoginForm from "../Rsvp/RsvpLoginForm";
import RsvpResponseForm from "../Rsvp/RsvpResponseForm";
import Carousel from "../Carousel/Carousel";
import lookback from '../../Images/lookback.jpg';
import bench from '../../Images/RosesBench.jpg';
import spin from '../../Images/Spin.jpg';
import hug from '../../Images/hug.jpg';
import picnic from '../../Images/picnic.jpg';
import hugMobile from '../../Images/hug-mobile.jpg';
import spinMobile from '../../Images/spin-mobile.jpg';
import leanMobile from '../../Images/lean-mobile.jpg';
import picnicMobile from '../../Images/picnic-mobile.jpg';
import RsvpAdminPage from "../Rsvp/RsvpAdminPage";
import RsvpClosed from "../Rsvp/RsvpClosed";

const getImages = (mobileView) => {
    if (mobileView) {
        return [leanMobile, hugMobile, spinMobile, picnicMobile]
    } else {
        return [lookback, bench, hug, spin, picnic]
    }
}

const RsvpStep = ({ data, handleLogin, defaults, setDefaults, rsvps, setRsvps }) => {
    if (rsvps !== null) {
        return <RsvpAdminPage rsvps={rsvps} />
    } else if (data.length > 0) {
        // return <RsvpResponseForm data={data} defaults={defaults} setDefaults={setDefaults} />
        return <RsvpClosed />
    } else {
        return <RsvpLoginForm callback={handleLogin} setDefaults={setDefaults} setRsvps={setRsvps} />
    }

}

const Rsvp = ({ mobileView, data, handleLogin, defaults, setDefaults, rsvps, setRsvps }) => {
    return (
        <Carousel imgArr={getImages(mobileView)}>
            <div className='absolute w-[100%] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 transition-all duration-500 ease-linear' style={{ padding: "1rem" }}>
                <RsvpStep data={data} handleLogin={handleLogin} defaults={defaults} setDefaults={setDefaults} rsvps={rsvps} setRsvps={setRsvps} />
            </div >
        </Carousel>
    )

}

export default Rsvp;